import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'  // 引入 VueRouter
import HomePage from './views/HomePage.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)  // 使用 VueRouter 插件

const routes = [
  { path: "/", component: HomePage }
]

const router = new VueRouter({
  routes
})

new Vue({
  render: h => h(App),
  router  // 将 router 添加到 Vue 实例中
}).$mount('#app')
