<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style> 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body, html{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: "Fira Sans","KaiTi", "STKaiTi", "楷体", "Kaiti SC", "Kaiti TC", "Noto Serif SC", "Noto Serif TC",sans-serif!important;
}
</style>
