<template>
  <div class="home">
    <div class="logo"><img src="../assets/logo.png" alt="iChinese.name logo - Your personalized Chinese name generator"></div>

    <div class="content-container">
      <h2 class="main-title">{{ titleText }}<span class="cursor" v-if="showCursor">|</span></h2>
      <p :key="currentSlideIndex" class="sub-title">{{ currentSlide.subtitle }}</p>
      <div class="line"></div>
    </div>
    
    <!-- 使用 vue-seamless-scroll 实现滚动效果 -->
    <div class="scrolling">
      <div class="title">Congratulations on getting a Chinese name</div>
      <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp" >
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in listData" :key="index">
            <div class="enname">{{ item.englishName }}</div>
            <div class="icon">|</div>
            <div class="cnname">
              {{ item.chineseName }}
              <span class="pinyin">{{ item.pinyin }}</span>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
    
    <!-- 名字生成器 -->
    <div class="name-generator">
      <h2>Generate Your Chinese Name</h2>
      <form @submit.prevent="generateName">
        <div class="name-item">
          <div class="name-label">
            <label for="englishName">Your English Name</label>
            <input type="text" id="englishName" v-model="englishName" required placeholder="Enter your English name"  autocomplete="off"/>
          </div>
          <div class="name-tips">It is recommended to enter only your first name without the last name. The pinyin syllables of the Chinese name make it feel more relatable. It also helps others associate and remember your Chinese and English names more easily.</div>
        </div>
        <div class="name-item">
          <div class="name-label">
            <label for="gender">Your Gender</label>
            <select id="gender" v-model="gender" required>
              <option value="" disabled selected>Select your gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="female">Nutral</option>
            </select>
          </div>
          <div class="name-tips">Just like English names, some words are more suitable for females, while others suit males. Of course, there are many Chinese names that are unisex. Please select your gender accurately to avoid choosing a name meant for the opposite gender.</div>
        </div>
        <div class="name-item">
          <div class="name-label">
            <label for="birthday">Your Birthday</label>
            <input type="date" id="birthday" v-model="birthday" required />
          </div>
          <div class="name-tips">In Chinese culture, a birthday carries many elements that make certain names more suitable for you. For example, zodiac signs, constellations, or famous figures born on the same day are all factors influenced by your birthday.</div>
        </div>
        <div class="name-item">
          <div class="name-label">
            <label for="popularity">Your Preference for Name Popularity?</label>
            <select id="popularity" v-model="popularity" required>
              <option value="" disabled selected>Select name popularity</option>
              <option value="classic">Classic [Names with cultural and historical significance.]</option>
              <option value="modern">Modern [Names matching contemporary aesthetic trends.]</option>
              <option value="unique">Unique [Rare and uncommon names with distinctiveness.]</option>
              <option value="common">Common [Familiar names that feel approachable.]</option>
            </select>
          </div>
          <div class="name-tips">Some Chinese names are very popular, while others are quite rare. Common names can create a sense of familiarity and help build rapport quickly. Rare names can showcase your uniqueness and avoid duplications or confusion.</div>
        </div>
        <div class="name-item">
          <div class="name-label">
            <label for="stylization">Your Preference for Name Style?</label>
            <select id="stylization" v-model="stylization" required>
              <option value="" disabled selected>Select name style</option>
              <option value="elegant">Elegant [Names reflecting grace, linked to art or virtue.]</option>
              <option value="strong">Strong [Names conveying strength and resolve.]</option>
              <option value="gentle">Gentle [Soft, warm, and approachable names.]</option>
              <option value="poetic">Poetic [Names with literary charm and imagery.]</option>
              <option value="nature-inspired">Nature-inspired [Names tied to nature, like mountains or flowers.]</option>
              <option value="mythical">Mythical [Derived from legends or cultural myths.]</option>
              <option value="prosperous">Prosperous [Names symbolizing wealth and fortune.]</option>
              <option value="scholarly">Scholarly [Names evoking wisdom or academic elegance.]</option>
              <option value="minimalist">Minimalist [Simple yet deeply meaningful names.]</option>
            </select>
          </div>
          <div class="name-tips">Chinese names often derive their meaning from individual characters or phrases, and some also come from poetic or literary references. The meaning and style of a Chinese name can vary, so matching the name's style with your personal style is essential.</div>
        </div>
        <div class="name-item">
               <div class="name-label">
               <label for="length">Your Preference for Name Length?</label>
               <select id="length" v-model="length" required>
                     <option value="" disabled selected>Select name length</option>
                     <option value="2">2</option>
                     <option value="3">3</option>
                     <option value="4">4</option>
                     <option value="random">random</option>
               </select>
               </div>
               <div class="name-tips">The "length" field specifies the total number of Chinese characters in the name, including both the family name (surname) and the given name. Chinese names typically consist of 2 to 4 characters, and the length can significantly influence the name's style and impact</div>
          </div>
        <button type="submit">Generate Name</button>
      </form>


      <!-- 弹出框 -->
      <div v-if="showModal" class="modal-overlay">
        <div class="nameModal">
          <div v-if="isLoading">
            <p>Generating Name...</p>
            <div class="progress-bar">
              <div class="progress" :style="{ width: progress + '%' }"></div>
            </div>
          </div>
          <div class="nameBox" v-else>
            <h3>Generated Name</h3>
            <div class="nameInfo">
              <div class="nameInfoItem">
                <div class="title">Name:</div> 
                <div class="info">{{ generatedName }} <i class="fa fa-volume-high" @click="readPinyin"></i></div>
              </div>
              <div class="nameInfoItem">
                <div class="title">Pinyin:</div> 
                <div class="info">{{ generatedNamePinyin }}</div>
              </div>
              <div class="nameInfoItem">
                <div class="title">Meaning:</div> 
                <div class="info">{{ nameMeaning }}</div>
              </div>
            </div>
            <div class="nameFoot">
              <button @click="closeModal" class="btnClose">Close</button>
              <button @click="regenerateName" class="btnRepet">Regenerate</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <!-- 网站功能介绍 -->
    <div class="features">
      <h3>How It Works</h3>
      <ul>
        <li>Enter your English name, gender, birthdate, and personality traits.</li>
        <li>Get your personalized Chinese name based on your input.</li>
        <li>Understand the cultural significance of your Chinese name.</li>
      </ul>
    </div>
    <!-- 网站评价 -->
    <div class="review">
      <h2>Review</h2>
      <div class="dg-container">
        <!-- <div class="nav">
          <div class="dg-prev" @click="prevReview()"><i class="fa fa-angle-left"></i></div>
          <div class="dg-next" @click="nextReview()"><i class="fa fa-angle-right"></i></div>
        </div> -->
        <div class="dg-wrapper">
          <div class="review-item" v-for="(review, index) in reviews" :key="index" :class="{ 'dg-center': currentIndex === index }" :style="getReviewStyle(index)">
            <img :src="review.image" alt="Reviewer Image" />
            <cite>{{ review.author }}</cite>
            <p>{{ review.content }}</p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 网站问答介绍 -->
    <div id="faq-section" class="faq-container">
      <h2 class="faq-title">F&Q</h2>
      <div class="faq-item" v-for="(item, index) in faqList" :key="index">
        <div class="faq-question" :class="{'active': item.show}" @click="toggleFaq(index)">
          {{ item.question }}
        </div>
        <transition name="faq-answer">
          <div v-if="item.show" class="faq-answer">
            {{ item.answer }}
          </div>
        </transition>
      </div>
    </div>

    <!-- 网站底部 -->
    <div class="social-icons">
      <a href="https://www.youtube.com" target="_blank" class="social-icon">
        <i class="fab fa-youtube"></i> YouTube
      </a>
      <a href="https://www.tiktok.com" target="_blank" class="social-icon">
        <i class="fab fa-tiktok"></i> TikTok
      </a>
    </div>


  </div>
</template>

<script>
import VueSeamlessScroll from 'vue-seamless-scroll';
//import pinyin from "pinyin";
import axios from 'axios';

export default {
  name: "HomePage",
  components: {
    VueSeamlessScroll,
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      currentSlideIndex: 0,
      currentSlide: {},
      titleText: "",
      titleIndex: 0,
      interval: null,
      typingInterval: null,
      showCursor: true,
      englishName: "",
      gender: "",
      birthday: "",
      popularity: "",
      stylization: "",
      length:"",
      generatedName: '诸葛四郎', // 你可以根据实际生成的姓名来动态赋值
      generatedNamePinyin: 'Zhu Ge Si Lang', // 模拟拼音数据，实际情况需要动态获取
      showModal: false,
      isLoading: false,
      progress: 0,
      nameMeaning: "",
      currentIndex: 0, // 当前显示的评论索引
            reviews: [
              {
                image: "https://www.gravatar.com/avatar/1234567890abcdef1234567890abcdef?s=200&d=mp",
                content: `"I’m so grateful for this platform. I needed a reliable service for my career, and this free website that helped me choose a Chinese name has brought great success. It has made networking and business growth much easier. This service has greatly helped my personal and professional life."`,
                author: "Jeff A Watts",
              },
              {
                image: "https://www.gravatar.com/avatar/abcdef1234567890abcdef1234567890?s=200&d=mp",
                content: `"Incredible experience! I used it for my academic work, and it made my studies more efficient. Choosing a Chinese name even helped me grow my network and make valuable connections. Highly recommend it for anyone looking to boost their career or studies."`,
                author: "Lynette van Niekerk",
              },
              {
                image: "https://www.gravatar.com/avatar/234567890abcdef1234567890abcdef?s=200&d=mp",
                content: `"I can’t thank this service enough. As someone growing my career and network, this website helped me choose the perfect Chinese name, which opened doors to new opportunities. It has made a real difference in my work and personal life."`,
                author: "Paulo Martins",
              }
            ],
      slides: [
        { title: "Find Your Perfect Chinese Name", subtitle: "Discover a Chinese name that truly reflects your personality and background, bridging cultures and giving you a unique identity in the Chinese language." },
        { title: "Embrace Chinese Culture", subtitle: "Let your new name be a meaningful link to the traditions and values of Chinese society, connecting you with the world in a special way." },
        { title: "Easy and Free", subtitle: "Just enter your details, and we'll suggest a personalized Chinese name at no cost, making the process both simple and enjoyable." }
      ],
      listData: [
      ],
      faqList: [
        {
          question: "Why do I need a Chinese name?",
          answer:
            "A Chinese name can help you integrate better into Chinese culture and build deeper connections in business, communication, or study.",
          show: false,
        },
        {
          question: "How can I get a Chinese name that suits me?",
          answer:
            "Simply fill in your English name, gender, birthdate, profession, and name preferences, and we will generate a name based on its phonetics and meaning.",
          show: false,
        },
        {
          question: "Does the generated name have cultural significance?",
          answer:
            "Yes, we combine Chinese traditional culture with the semantics of your English name to design a name that suits your identity.",
          show: false,
        },
        {
          question: "Is there any fee for getting a name?",
          answer:
            "Name generation is completely free. You only need to watch one Google ad before generating a name.",
          show: false,
        },
        {
          question: "Can I regenerate a name if I am not satisfied?",
          answer:
            "Yes, the system supports multiple generations until you are satisfied.",
          show: false,
        },
        {
          question: "Will my information be stored or shared?",
          answer:
            "We are committed to protecting user privacy. Your personal information will not be stored or shared.",
          show: false,
        },
        {
          question: "What principles are used to generate the names?",
          answer:
            "The generation rules are based on the pronunciation and meaning of your English name, your preferences, and the characteristics of Chinese culture.",
          show: false,
        },
        {
          question:
            "Can I use the generated Chinese name for trademarks or official documents?",
          answer:
            "The generated names are for reference only. For formal purposes, we recommend consulting professional advisors.",
          show: false,
        },
        {
          question: "What languages does the website support?",
          answer:
            "Currently, we only support English, but more languages will be added in the future.",
          show: false,
        },
        {
          question: "Is the website mobile-friendly?",
          answer:
            "Yes, our website is responsive and supports all devices, including mobile phones and tablets.",
          show: false,
        },
      ],
    };
  },
  mounted() {
    this.startSlideshow();
    this.processPinyin();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.typingInterval);
  },
  methods: {
    nextReview() {
          console.log("Next Review clicked!"); // 调试输出
          this.currentIndex = (this.currentIndex + 1) % this.reviews.length;
        },
        prevReview() {
          console.log("Previous Review clicked!"); // 调试输出
          this.currentIndex = (this.currentIndex - 1 + this.reviews.length) % this.reviews.length;
        },
        getReviewStyle(index) {
          const offset = index - this.currentIndex;
          const total = this.reviews.length;
          const normalizedOffset = offset < 0 ? offset + total : offset;
    
          // 根据偏移量设置样式
          switch (normalizedOffset) {
            case 0: // 当前项
              return {
                transform: "translateZ(0) scale(1)",
                opacity: 1,
              };
            case 1: // 下一项
              return {
                transform: "translateX(300px) translateZ(-150px) scale(0.8)",
                opacity: 0.5,
              };
            case total - 1: // 上一项
              return {
                transform: "translateX(-300px) translateZ(-150px) scale(0.8)",
                opacity: 0.5,
              };
            default: // 其他项
              return {
                transform: "translateZ(-300px) scale(0.6)",
                opacity: 0,
              };
          }
        },
    toggleFaq(index) {
      // 将所有问答折叠，除了点击的那个
      this.faqList.forEach((item, i) => {
        item.show = i === index ? !item.show : false;
      });
    },
    processPinyin() {
      // 将标题、描述和名字列表的数据转换为拼音
     /* this.slides.forEach((slide) => {
        slide.titlePinyin = pinyin(slide.title, { style: pinyin.STYLE_NORMAL })
          .flat()
          .join(" ");
        slide.subtitlePinyin = pinyin(slide.subtitle, { style: pinyin.STYLE_NORMAL })
          .flat()
          .join(" ");
      });
    
      this.listData = this.listData.map((item) => ({
        ...item,
        cnnamePinyin: pinyin(item.cnname, { style: pinyin.STYLE_NORMAL })
          .flat()
          .join(" "),
      }));*/
	axios.post('http://ichinese.name:8080/api/names/getLastest', null, {headers: {'Content-Type': 'application/json'}})
		.then(response => {
			this.listData = response.data;
		})
		.catch(error => {
			console.log(error);
			alert('Error occurred.');
		});
	},
    startSlideshow() {
      this.currentSlide = this.slides[this.currentSlideIndex];
      this.startTypingEffect();

      this.interval = setInterval(() => {
        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
        this.currentSlide = this.slides[this.currentSlideIndex];
        this.startTypingEffect();
      }, 8000);
    },
    startTypingEffect() {
      clearInterval(this.typingInterval);
      this.titleText = "";
      this.titleIndex = 0;
      this.showCursor = true;

      this.typingInterval = setInterval(() => {
        if (this.titleIndex < this.currentSlide.title.length) {
          this.titleText += this.currentSlide.title[this.titleIndex];
          this.titleIndex++;
        } else {
          clearInterval(this.typingInterval);
          this.showCursor = false;
        }
      }, 150);
    },
    /*generateName() {
      this.showModal = true;
      this.startLoading(() => {
        this.showGeneratedName();
      });
      if (this.englishName && this.gender && this.birthday) {
        this.generatedName = `李${this.englishName[0]} (${this.gender === 'male' ? '男' : '女'})`;
      } else {
        this.generatedName = '';
        alert('Please fill all required fields!');
      }
    },*/
generateName() {
	this.generatedName = '';
	this.generatedNamePinyin = '';
	this.nameMeaning = '';
  // 验证输入字段是否填写完整
  if (this.englishName && this.gender && this.birthday && this.popularity && this.stylization) {
    this.showModal = true;
    this.startLoading(() => {
      // 使用 axios 发送 POST 请求
      axios.post('http://ichinese.name:8080/api/names/generate', null, {
	params:{
		englishName: this.englishName,
		gender: this.gender,
		birthDate: this.birthday,
		popularity: this.popularity,
		style: this.stylization,
		length:this.length
	},
	headers: {
		'Content-Type': 'application/json'
	}
	})
      .then(response => {
	this.isLoading = false;
        if (response.data.code == 0) {
		this.generatedName = response.data.data.chineseName;
		this.generatedNamePinyin = response.data.data.pinyin;
		this.nameMeaning = response.data.data.meaning;
        } else {
		alert('Failed to generate name. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Error occurred while generating name.');
      });
    });
  } else {
    alert('Please fill all required fields!');
  }
},
    regenerateName() {
	this.generateName();
    },
    startLoading(callback) {
      this.isLoading = true;
      this.progress = 0;
      let interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 5;
        } else {
          clearInterval(interval);
          if (callback) callback();
        }
      }, 100);
    },
    /*showGeneratedName() {
      this.generatedName = "诸葛四郎"; // 模拟的姓名数据
      this.generatedNamePinyin = "Zhu Ge Si Lang";
      this.nameMeaning = "Bright and intelligent.Bright and intelligent.Bright and intelligent.";
    },*/
    closeModal() {
      this.showModal = false;
      this.englishName = '';
      this.gender = '';
      this.birthday = '';
      this.popularity = '';
      this.stylization = '';
    },
    readPinyin() {
      // 使用SpeechSynthesis API来朗读拼音
      const pinyin = this.generatedName;  // 获取拼音
      const utterance = new SpeechSynthesisUtterance(pinyin);  // 创建朗读对象
      utterance.lang = 'zh-CN';  // 设置朗读语言为中文（如果拼音是中文发音）
      window.speechSynthesis.speak(utterance);  // 开始朗读
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
}

.home {
  position: relative;
  text-align: center;
}
.logo {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.logo img {
  width: 250px;
}
.pinyin {
  display: block;
  font-size: 10px;
  color: #999;
  height: 20px;
  line-height: 20px;
}
.content-container {
  background: #5025d1 url(/src/assets/banner.jpg) no-repeat center center;
  background-size: cover;
  height: 400px;
  color: #fff;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-title {
  font-size: 2em;
  margin-top: 150px;
  transition: opacity 0.5s;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.cursor {
  font-size: 1.2em;
  color: #fff;
  animation: blink 0.8s infinite step-end;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
.sub-title {
  width: 60%;
  font-size: 1.2em;
  text-align: center;
  margin: 20px auto 0;
  line-height: 30px;
  font-style: italic;
  font-weight: 200;
  opacity: 0;
  animation: fadeIn 3s forwards;
  animation-delay: 2s;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.line{
  width: 100px;
  height: 30px;
  margin: 0 auto;
  border-bottom: 2px solid #fff;
}
.scrolling{
  width: 60%;
  margin: -30px auto 0 auto;
  background: #fff;
  border-radius: 250px 250px 0 0;
  overflow: hidden;
  .title{
    border: 1px solid rgb(92, 64, 168);
    background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
    color: #fff;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
}
.warp {
  width: 130px * 4;
  height: 90px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        min-width: 120px;
        margin-right: 10px;
        text-align: center;
        .enname{
          color: #666;
        }
        .enname::first-letter{
          text-transform: uppercase;
        }
        .icon{
          font-size: 12px;
          padding: 5px 0;
          color: #e8e8e8;
        }
        .cnname{
          color: #5025d1;
        }
      }
    }
  }
}
.name-generator {
  border: 2px solid #f8f8f8;
  width: 70%;
  border-radius: 30px;
  margin: 40px auto 0 auto;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  .name-item{
    padding: 10px 0;
    display: flex;
    justify-content: left;
    align-items: center;
    .name-label{
      width: 40%;
      text-align: left;
      label{
        font-size: 12px;
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
      }
    }
    .name-tips{
      width: 55%;
      font-size: 12px;
      color: #888;
      text-align: left;
    }
  }
}

.name-generator h2 {
  font-size: 32px;
  color: #5025d1;
  padding: 15px 0;
  font-weight: 600;
}

.name-generator form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-generator input,
.name-generator select {
  margin-bottom: 10px;
  padding: 8px 0;
  font-size: 13px;
  width: 90%;
  border: 2px solid #5025d1;
  border-radius: 5px;
  color: #5025d1;
  font-style: italic;
  font-weight: 600;
  text-indent: 10px;
}

.name-generator button {
  background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
  color: white;
  padding: 15px 20px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  width: 250px;
  margin: 20px 0;
  border-radius: 30px;
}

.name-generator button:hover {
  background-color: #3c1fb3;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameModal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 500px;
  text-align: center;
}
.nameBox{
  padding: 10px;
}
.nameBox h3{
  font-size: 28px;
  height: 30px;
  line-height: 30px;
  color: #5025d1;
}
.nameInfo{
  padding: 20px;
}
.nameInfoItem{
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  line-height: 24px;
  margin-bottom: 5px;
  font-size: 16px;
}
.nameInfoItem .title{
  width: 30%;
  font-weight: 600;
}
.nameInfoItem .info{
  width: 70%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.nameInfoItem .info i{
  margin-left: 10px;
  font-size: 20px;
  color: #5025d1;
}
.nameFoot{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.nameFoot button{
  margin: 0 5px;
}
.nameFoot .btnClose{
  background: #f8f8f8;
  border: none;
  color: #999;
  font-size: 14px;
}
.nameFoot .btnClose:hover{
  color: #666;
  background: #f6f6f6;
}
.nameFoot .btnRepet{
  background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.progress-bar {
  background: #f2f2f2;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
  transition: width 0.1s linear;
}
.review {
  position: relative;
  height: 600px;
  background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.review h2{
  display: block;
  font-size: 28px;
  color: #fff;
  position: absolute;
  top: 50px;
}
.dg-container {
  width: 800px;
  height: 400px;
  position: relative;
}
.nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}
.nav div {
  cursor: pointer;
  font-size: 2rem;
  color: #666;
}
.dg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform-style: preserve-3d;
}
.review-item {
  position: absolute;
  width: 300px;
  height: 360px;
  background: #f2f3f7;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, opacity 0.5s;
}
.review-item img {
  margin-top: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.review-item h2 {
  font-size: 1.2rem;
  color: #333;
  margin: 10px 0;
}
.review-item p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  padding: 10px;
}
.review-item cite {
  font-size: 1rem;
  height: 30px;
  line-height: 30px;
  color: #888;
  display: block;
  margin-top: 10px;
}

.faq-container {
  padding: 20px 0;
  width: 40%;
  margin: 0 auto;
}

.faq-title {
  font-size: 28px;
  color: #5025d1;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 15px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  color: #666;
  text-align: left;
}

.faq-question.active {
  color: #5025d1;
  text-align: left;
}

.faq-answer {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-align: left;
  padding-left: 10px;
  font-style: italic;
}

.features {
  margin: 40px auto;
}

.features h3 {
  font-size: 28px;
  color: #5025d1;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 30px 0;
  border-top: 1px solid rgb(92, 64, 168);
  background: linear-gradient(92deg, rgb(174, 1, 255) 0%, rgb(137, 42, 242) 100%);
}

.social-icon {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  transition: color 0.3s ease;
  opacity: 0.5;
  margin: 0 10px;
}

.social-icon:hover {
  opacity: 1;
}

.social-icon i {
  margin-right: 8px;
}

</style>
